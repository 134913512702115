import React, {useEffect} from "react"
import {window} from "browser-monads"

import SEO from "../components/seo"

const IndexPage = () => {
  useEffect(() => {
    window.location.href="https://splash.experienceubuntu.com"
  })

  return(
    <React.Fragment>
      <SEO title="I AM BECAUSE WE ARE" description="Wellbeing and reciprocity with our Self, our community, and Earth.
    Yoga. Experiences. Reflective Dialogue. Business Consulting."/>
      <main id="splash"></main>
    </React.Fragment>
  )
}

export default IndexPage
